import React, { useState, useEffect } from "react"; // Fragment
import {
  // BrowserRouter as Router,
  // Route,
  // Routes,
  Link,
  useNavigate,
} from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import * as yup from "yup";
import Breadcrumb from "../../other/Breadcrumb/Breadcrumb";

import "./support.css";
import "bootstrap/dist/css/bootstrap.min.css";

const Support = () => {
  const [formEnabled, setFormEnabled] = useState(true);
  const [email, setEmail] = useState("");
  const [phone, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [sex, setSex] = useState("male");
  const [message, setMessage] = useState("");
  const [formType, setFormType] = useState("formWaitingListAgentPurchases");
  const [formError, setFormError] = useState("");
  const [formSuccess, setFormSuccess] = useState(false);
  const [isFormHidden, setIsFormHidden] = useState(false);

  const [unlockParagraphClickCount, setUnlockParagraphClickCount] = useState(0);
  const [env, setEnv] = useState("production");
  const [progressBarValue, setProgressBarValue] = useState(0);

  // usenavigate
  const navigate = useNavigate();

  const breadcrumbItems = [
    { label: "Home", url: "/" },
    { label: "Support", url: "/support", active: true },
  ];

  // setTimeout to update the progressBarValue by 1 every 100ms
  useEffect(() => {
    const interval = setInterval(() => {
      if (progressBarValue < 100) {
        setProgressBarValue(progressBarValue + 1);
      }
    }, 50);
    return () => clearInterval(interval);
  }, [progressBarValue]);

  const onFormSubmit = (e) => {
    e.preventDefault();
    setFormError("");

    // validate form against yup schema
    const schema = yup.object().shape({
      email: yup.string().max(100).email().required(),
      firstName: yup.string().max(100).required(),
      lastName: yup.string().max(100).required(),
      sex: yup.string().max(6).required(),
      message: yup.string().max(1000).optional(),
      phone: yup.string().max(1000).optional(),
    });

    schema
      .validate({ email, firstName, lastName, sex, message, phone })
      .then(() => {
        setFormEnabled(false);
        setProgressBarValue(0);

        // build the payment request
        const req = {
          email: email ? email : "blank",
          firstName: firstName ? firstName : "blank",
          lastName: lastName ? lastName : "blank",
          phone: phone ? phone : "blank",
          sex,
          message: "Support Question; message: " + message,
          formType,
        };

        // send the payment request to the server
        // local endpoint
        const formApi = {
          local: "http://localhost:3001/formWaitingListAgentPurchases",
          local2:
            "https://ps5lyq6sa8.execute-api.us-east-1.amazonaws.com/default/formWaitingListAgentPurchases",
          production:
            "https://ps5lyq6sa8.execute-api.us-east-1.amazonaws.com/default/formWaitingListAgentPurchases",
        };

        fetch(formApi[env], {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // access-control-allow-origin: *

            "Access-Control-Allow-Origin": "*", // this is the important part
          },
          body: JSON.stringify(req),
        })
          .then((res) => res.json())
          .then(
            (data) => {
              console.log("data", data);
              setFormEnabled(false);
              setProgressBarValue(100);

              // if data.error is set, set the error message to formError
              if (data.error) {
                // TODO: handle error cases
                setFormError(JSON.stringify(data.error));
              } else {
                setFormSuccess(true);
              }
            } // end of .then()
          ); // end of fetch()
      })
      .catch((err) => {
        console.log("err", err);
        setFormError(err.errors[0]);
        setFormEnabled(true);
      });
  };

  const getForm = () => {
    if (formEnabled) {
      return (
        <>
          {/* form with text box for QR code link */}
          <div className="form">
            <form>
              {/* first name */}
              <div className="form-group">
                <label htmlFor="firstName">First Name</label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder="Enter First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>

              {/* last name */}
              <div className="form-group">
                <label htmlFor="lastName">Last Name</label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  placeholder="Enter Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>

              <div className="form-group" style={{ textAlign: "center" }}>
                <label>Sex</label>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ marginRight: "20px" }}>
                    <input
                      type="radio"
                      id="male"
                      name="sex"
                      value="male"
                      onChange={() => setSex("male")}
                      checked={sex === "male"}
                    />
                    <label htmlFor="male">Male</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="female"
                      name="sex"
                      value="female"
                      onChange={() => setSex("female")}
                      checked={sex === "female"}
                    />
                    <label htmlFor="female">Female</label>
                  </div>
                </div>
                <br />
              </div>

              {/* email */}
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <br />
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Enter Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              {/* Phone Number */}
              <div className="form-group">
                <label htmlFor="phoneNumber">Phone Number</label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  id="phoneNumber"
                  placeholder="Enter Phone Number"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>

              {/* message */}
              <div className="form-group">
                <label htmlFor="message">
                  Questions / Comments / Suggestions
                </label>
                <small id="message-help" className="form-text text-muted">
                  Leave any questions, comments, or suggestions here
                </small>
                <textarea
                  type="text"
                  className="form-control"
                  id="message"
                  placeholder="Enter Questions"
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <br />

              {/* Error Message Display Div */}
              {formError ? (
                <div
                  id="error-message"
                  className="alert alert-danger"
                  role="alert"
                >
                  <strong>Error!</strong> {formError}
                </div>
              ) : null}

              {/* submit button */}
              <button
                type="submit"
                className="btn btn-lg btn-block btn-primary"
                style={{
                  fontSize: "20px",
                  backgroundColor: "black",
                  color: "orange",
                  fontWeight: "bold",
                }}
                onClick={(e) => onFormSubmit(e)}
              >
                Submit
              </button>
            </form>
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {/* breadcrumb links to higher pages */}
      <div style={{ alignItems: "center" }}>
        <Breadcrumb items={breadcrumbItems} />
      </div>
      <div className="outer-home-container">
        <div className="home">
          <div>
            <h3>Support Questions</h3>

            {/* Error Message Display Div */}
            {formError ? (
              <div
                id="error-message"
                className="alert alert-danger"
                role="alert"
              >
                <strong>Error!</strong> {formError}
              </div>
            ) : null}

            {/* Success Message Display Div */}
            {formSuccess ? (
              <div
                id="success-message"
                className="alert alert-success"
                role="alert"
              >
                <strong>Success!</strong> We received your message.
              </div>
            ) : null}

            {/* Loading Message Display Div */}
            {!formEnabled && !formError && progressBarValue != 100 ? (
              <>
                <div
                  id="loading-message"
                  className="alert alert-info"
                  role="alert"
                >
                  <strong>
                    If you are not shown a success message within 10 seconds,
                    please refresh the page and try again.
                  </strong>
                </div>
                {/* create a loading spinner with inline css */}
                <ProgressBar now={progressBarValue} animated />
              </>
            ) : null}

            {getForm()}

            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
