import React from "react";

const Breadcrumb = ({ items }) => (
  <div className="breadcrumbDiv">
    <nav
      aria-label="breadcrumb"
      style={{
        width: "100%",
        backgroundColor: "#212529",
      }}
    >
      <ol className="breadcrumb" style={{ paddingLeft: "20px" }}>
        {items.flatMap((item, index) => [
          <li
            key={`item-${index}`}
            className={`breadcrumb-item${item.active ? " active" : ""}`}
            aria-current={item.active ? "page" : undefined}
          >
            <a
              style={{ color: "white" }}
              href={item.url}
              rel="noopener noreferrer"
            >
              {item.label}
            </a>
          </li>,
          index < items.length - 1 && (
            <li key={`separator-${index}`} style={{ color: "white" }}>
              &nbsp;&nbsp;&gt;&nbsp;&nbsp;
            </li>
          ),
        ])}
      </ol>
    </nav>
  </div>
);

export default Breadcrumb;
