import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";

export default function DataDeletion() {
  return (
    <div className="DataDeletion">
      <h2>Data Deletion Instructions</h2>
      <p>
        To delete your account and associated data from our application, please
        follow these steps:
      </p>
      <ol>
        <li>Log in to your account on our platform.</li>
        <li>
          Navigate to the <strong>Settings</strong> tab in the navigation menu.
        </li>
        <li>
          Scroll to the bottom of the page and locate the{" "}
          <strong>Delete Account</strong> button.
        </li>
        <li>
          Click the <strong>Delete Account</strong> button.
        </li>
        <li>
          A confirmation box will appear. To confirm your request, type{" "}
          <strong>"delete account"</strong> into the confirmation box.
        </li>
        <li>
          Click the <strong>Confirm</strong> button to finalize the deletion
          process.
        </li>
      </ol>
      <p>
        Once you confirm, your account and associated data will be permanently
        deleted from our system. If you have any issues or questions, please
        contact our support team for assistance.
      </p>
    </div>
  );
}
