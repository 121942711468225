import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";

export default function Terms() {
  return (
    <div className="Terms">
      {/* Title */}
      <h2>Active Beaches Terms of Service</h2>
      <br />
      {/* Terms of Service */}
      <p>
        <strong>
          Please read these Terms of Service ("Terms", "Terms of Service")
          carefully before using the Active Beaches website, social media
          accounts, group messages, or email notifications (collectively, the
          "Service") operated by Active Beaches ("us", "we", or "our").
        </strong>
      </p>
      <p>
        Your access to and use of the Service is conditioned on your acceptance
        of and compliance with these Terms. These Terms apply to all visitors,
        users and others who access or use the Service.
      </p>
      <p>
        By accessing or using the Service you agree to be bound by these Terms.
        If you disagree with any part of the terms then you may not access the
        Service.
      </p>
      <h2>General Group Rules</h2>
      <h6 style={{ fontWeight: "bold" }}>Don't be drunk</h6>
      <h6 style={{ fontWeight: "bold" }}>Don't be creepy</h6>
      <h6 style={{ fontWeight: "bold" }}>
        Don't be sensual (even if it's consensual)
      </h6>
      <h6 style={{ fontWeight: "bold" }}>Don't be rude</h6>
      <h6 style={{ fontWeight: "bold" }}>Don't be inappropriate</h6>
      <hr />
      <h6 style={{ fontWeight: "bold" }}>Drunk Behavior</h6>
      <p>
        If you show up drunk to an event or drink to excess at an event, you
        will be asked to leave and may be face further action up to and
        including permanent removal from the group without refund or
        compensation of any kind.
      </p>
      <h6 style={{ fontWeight: "bold" }}>Creepy Behavior</h6>
      <p>
        If you engage in things that are considered creepy, you will be asked to
        leave and may be face further action up to and including permanent
        removal from the group without refund or compensation of any kind.
      </p>
      <p>Creepy actions include but are not limited to the following: </p>
      {/* italic div */}
      <div style={{ fontStyle: "italic" }}>
        <p>
          Sending multiple direct messages within our group chat platform
          without receiving a response
        </p>
        <p>Sending high numbers of unwanted messages or texts</p>
        <p>Stalking</p>
        <p>Unwanted physical contact</p>
        <p>
          Following people around at events in a way that is inappropriate in
          the view of the organizers
        </p>
      </div>
      <br />

      <h6 style={{ fontWeight: "bold" }}>Sensual Behavior</h6>
      <p>
        If you engage in sensual activities, you will be asked to leave and may
        be face further action up to and including permanent removal from the
        group without refund or compensation of any kind.
      </p>
      <p>Sensual activities include but are not limited to the following:</p>
      <div style={{ fontStyle: "italic" }}>
        <p>Touching in a sensual way</p>
        <p>Kissing</p>
        <p>Sexual activity</p>
        <p>Unwanted sexual advances / comments / jokes / innuendos</p>
      </div>
      <h6 style={{ fontWeight: "bold" }}>Rude Behavior</h6>
      <p>
        If you are rude to other members of the group, you will be asked to
        leave and may be face further action up to and including permanent
        removal from the group without refund or compensation of any kind.
      </p>
      <p>Rude behavior includes but is not limited to the following:</p>
      <div style={{ fontStyle: "italic" }}>
        <p>Insulting other members</p>
        <p>Being disrespectful</p>
        <p>Swearing</p>
        <p>Being mean</p>
        <p>Being aggressive</p>
        <p>Being confrontational</p>
        <p>Inappropriate yelling</p>
      </div>
      <h6 style={{ fontWeight: "bold" }}>Inappropriate Behavior</h6>
      <p>
        If you engage in inappropriate behavior, you will be asked to leave and
        may be face further action up to and including permanent removal from
        the group without refund or compensation of any kind.
      </p>
      <p>
        Inappropriate behavior includes but is not limited to the following:
      </p>
      <div style={{ fontStyle: "italic" }}>
        <p>Public urination</p>
        <p>Public nudity</p>
        <p>Public sexual activity</p>
        <p>Public intoxication</p>
        <p>drug use</p>
        <p>fighting</p>
        <p>destruction of property</p>
        <p>theft</p>
        <p>vandalism</p>
        <p>harassment</p>
        <p>assault</p>
        <p>battery</p>
        <p>trespassing</p>
        <p>loitering</p>
        <p>littering</p>
        <p>Public indecent exposure</p>
      </div>

      <h2>Accounts</h2>
      <p>
        When you create an account with us, you must provide us information that
        is accurate, complete, and current at all times. Failure to do so
        constitutes a breach of the Terms, which may result in immediate
        termination of your account on our Service.
      </p>
      <p>
        You are responsible for safeguarding the password that you use to access
        the Service and for any activities or actions under your password,
        whether your password is with our Service or a third-party service.
      </p>
      <p>
        You agree not to disclose your password to any third party. You must
        notify us immediately upon becoming aware of any breach of security or
        unauthorized use of your account.
      </p>
      <h2>Links To Other Web Sites</h2>
      <p>
        Our Service may contain links to third-party web sites or services that
        are not owned or controlled by The Service.
      </p>
      <p>
        The Service has no control over, and assumes no responsibility for, the
        content, privacy policies, or practices of any third party web sites or
        services. You further acknowledge and agree that The Service shall not
        be responsible or liable, directly or indirectly, for any damage or loss
        caused or alleged to be caused by or in connection with use of or
        reliance on any such content, goods or services available on or through
        any such web sites or services.
      </p>
      <p>
        We strongly advise you to read the terms and conditions and privacy
        policies of any third-party web sites or services that you visit.
      </p>
      <h2>Termination</h2>
      <p>
        We may terminate or suspend access to our Service immediately, without
        prior notice or liability, for any reason whatsoever, including without
        limitation if you breach the Terms.
      </p>
      <p>
        We may terminate or suspend your account immediately, without prior
        notice or liability, for any reason whatsoever, including without
        limitation if you breach the Terms.
      </p>
      <p>
        Upon termination, your right to use the Service will immediately cease.
        If you wish to terminate your account, you may simply discontinue using
        the Service.
      </p>
      <p>
        All provisions of the Terms which by their nature should survive
        termination shall survive termination, including, without limitation,
        ownership provisions, warranty disclaimers, indemnity and limitations of
        liability.
      </p>
      <h2>Governing Law</h2>
      <p>
        These Terms shall be governed and construed in accordance with the laws
        of Florida, United States, without regard to its conflict of law
        provisions.
      </p>
      <p>
        Our failure to enforce any right or provision of these Terms will not be
        considered a waiver of those rights. If any provision of these Terms is
        held to be invalid or unenforceable by a court, the remaining provisions
        of these Terms will remain in effect. These Terms constitute the entire
        agreement between us regarding our Service, and supersede and replace
        any prior agreements we might have between us regarding the Service.
      </p>
      <h2>Changes</h2>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material we will try to provide at
        least 30 days notice prior to any new terms taking effect. What
        constitutes a material change will be determined at our sole discretion.
      </p>
      <p>
        By continuing to access or use our Service after those revisions become
        effective, you agree to be bound by the revised terms. If you do not
        agree to the new terms, please stop using the Service.
      </p>
    </div>
  );
}
