import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MapPin, Calendar, Clock, DollarSign, Trophy } from "lucide-react";
import StripeBuyButton from "../../other/StripeBuyButton/StripeBuyButton";
import Breadcrumb from "../../other/Breadcrumb/Breadcrumb";

import "./tournament.css";

const breadcrumbItems = [
  { label: "Home", url: "/" },
  { label: "Free Volleyball Tournament", url: "/tournament", active: true },
];

const Tournament = () => {
  const navigate = useNavigate();
  const [registrationOption, setRegistrationOption] = useState(0);

  return (
    <>
      <div className="hero-banner">
        <div className="hero-overlay"></div>
        <div className="hero-content">
          <h1 className="text-white">Free Beach Volleyball Fours Tournament</h1>
        </div>
      </div>
      <Breadcrumb items={breadcrumbItems} />
      <div className="page container space-y-8">
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSf15Kco4CZxEvFDCJx28XyBOo6v_JzcjujseVIIX7IqExB_ow/viewform?usp=sf_link"
          target="_blank"
          rel="noopener noreferrer"
          className="btn signup-button"
        >
          Sign Me Up
        </a>
        <div className="card space-y-4">
          <h2 className="card-title">Event Details</h2>
          <div className="event-details space-y-1">
            <p>
              <MapPin className="icon" /> Florida Blvd Beach Access
              <br />
              100 Florida Blvd
              <br />
              Neptune Beach, FL 32266
            </p>
            <p>
              <Calendar className="icon" /> Sunday, Nov 10, 2024
            </p>
            <p>
              <Clock className="icon" /> 10 AM - 2 PM
            </p>
            <p>
              <DollarSign className="icon" /> FREE
            </p>
          </div>
        </div>
        <div className="card space-y-2">
          <h3 className="card-title">Prizes</h3>
          <p>
            <Trophy className="icon" /> $200 for the winning team
            <br />
            (split 4 ways - $50 each)
          </p>
          <p>
            <Trophy className="icon" /> $100 for the runner up team
            <br />
            (split 4 ways - $25 each)
          </p>
        </div>
        <div className="card space-y-2">
          <h3 className="card-title">Important Info</h3>
          <p>
            <strong>Preregistration is required.</strong>
          </p>
          <p>
            Maximum 40 individual entries, then we will close signups. Entries
            will close on Friday, Nov 9 at 11:59pm.
          </p>
          <p>
            🏖️ Join us for a fun day of beach volleyball at Florida Blvd Beach
            Access in Neptune Beach. (this is the same street as Jarboe Park so
            you can park there if there is no street parking available)
          </p>
          <p>
            This event is open to all skill levels, from beginners to
            experienced players. We'll have 4-person teams, so you can sign up
            to play with friends or make new ones.
          </p>
          <p>So grab your gear and get ready to hit the sand!</p>
          <p>
            First game starting at 10am. We expect the tournament to last until
            2pm depending on the number of teams.
          </p>
        </div>
        <div className="card space-y-2">
          <h3 className="card-title">Parking</h3>
          <p>
            You can park at Jarboe Park in Neptune Beach and walk out the beach.
          </p>
        </div>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSf15Kco4CZxEvFDCJx28XyBOo6v_JzcjujseVIIX7IqExB_ow/viewform?usp=sf_link"
          target="_blank"
          rel="noopener noreferrer"
          className="btn signup-button"
        >
          Sign Me Up
        </a>
        {/* registration-vball-tourney */}
        {/* <button
          onClick={() => navigate("/registration-vball-tourney")}
          className="btn btn-block"
          style={{
            backgroundColor: "#71368A",
            color: "#FFFFFF",
            fontSize: "24px",
            height: "48px",
            marginBottom: "10px",
          }}
        >
          Sign Me Up
        </button> */}
        <p className="disclaimer-card">
          There are certain risks to both participating in and attending a
          volleyball tournament. And by participating in this tournament and
          also attending this tournament as a spectator and also by filling out
          this form, you agree to hold Active Beaches, its partners, and
          organizers harmless for any possible injuries, losses, or other
          negative consequences that may happen to you.
        </p>
        <p>
          Questions? Contact us at{" "}
          <a href="mailto:activebeachesjax@gmail.com" className="contact-link">
            activebeachesjax@gmail.com
          </a>
        </p>
        <div>
          <img
            src={`https://s3.amazonaws.com/social-images-public/main/vball-tourney-parking-nov-2024.jpeg`}
            className="responsive-img"
          />
        </div>

        <p className="fun-text">Get ready for fun times!</p>
        <div>
          <img
            src={`https://s3.amazonaws.com/social-images-public/main/medium_beach_volleyball_group_20240506a.jpeg`}
            className="responsive-img"
          />
        </div>
        <p>Come out and play!</p>
        <div>
          <img
            src={`https://s3.amazonaws.com/social-images-public/main/1.jpg`}
            className="responsive-img"
          />
        </div>
        <p>Don't miss out!</p>
        <div>
          <img
            src={`https://s3.amazonaws.com/social-images-public/main/3.jpg`}
            className="responsive-img"
          />
        </div>
        <div className="footer">
          <p>&copy; Active Beaches {new Date().getFullYear()}</p>
        </div>
      </div>
    </>
  );
};

export default Tournament;
