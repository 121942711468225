import React, { useState, useEffect } from "react"; // Fragment
import {
  // BrowserRouter as Router,
  // Route,
  // Routes,
  Link,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import * as yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import axios from "axios";
import Breadcrumb from "../../other/Breadcrumb/Breadcrumb";
import SocialLinks from "../../other/SocialLinks/SocialLinks";

import "./photos.css";
import "bootstrap/dist/css/bootstrap.min.css";

const EVENT_API_URL = `https://4cljs7mcdi.execute-api.us-east-1.amazonaws.com/default/socialevents`;

const Photos = () => {
  const [formEnabled, setFormEnabled] = useState(true);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [message, setMessage] = useState("");
  const [formType, setFormType] = useState("waitingListAgentPurchases");
  const [formError, setFormError] = useState("");
  const [formSuccess, setFormSuccess] = useState(false);
  const [failedToFindEvent, setFailedToFindEvent] = useState(false);
  const [waitingForSingleEvent, setWaitingForSingleEvent] = useState(true);
  const [pastEvents, setPastEvents] = useState(true);

  const showLinksToOldEvents = false;
  const loadEvents = false;

  const { eventId } = useParams();

  const currentDate = new Date();
  const showHavanaNightsButton = currentDate <= new Date("2024-11-30");

  // get the query param "isPastEvents" from the url and set pastEvents to true if it's true
  // useEffect(() => getIsPastEventsQueryParams(), []);
  const getIsPastEventsQueryParams = () => {
    // const params = new URLSearchParams(location.search);
    // const isPastEvents = Boolean(params.get("isPastEvents"));
    // if (isPastEvents) {
    //   setPastEvents(true);
    //   console.log("setting pastEvents to true: ", isPastEvents);
    // } else {
    //   setPastEvents(false);
    //   console.log("setting pastEvents to false, ", isPastEvents);
    // }
    return pastEvents;
  };

  const navigate = useNavigate();
  const location = useLocation();

  const [unlockParagraphClickCount, setUnlockParagraphClickCount] = useState(0);
  const [env, setEnv] = useState("production");
  const [progressBarValue, setProgressBarValue] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState({});

  // setTimeout to update the progressBarValue by 1 every 100ms
  // useEffect(() => {
  //   async function progressBar() {
  //     const interval = setInterval(() => {
  //       if (progressBarValue < 100) {
  //         setProgressBarValue(progressBarValue + 1);
  //       }
  //     }, 50);
  //     return () => clearInterval(interval);
  //   }
  //   progressBar();
  // }, [progressBarValue]);

  const onFormSubmit = (e) => {
    e.preventDefault();
    setFormError("");

    // validate form against yup schema
    const schema = yup.object().shape({
      email: yup.string().max(100).email().required(),
      firstName: yup.string().max(100).required(),
      lastName: yup.string().max(100).required(),
      message: yup.string().max(1000).required(),
    });

    schema
      .validate({ email, firstName, lastName, message })
      .then(() => {
        setFormEnabled(false);
        setProgressBarValue(0);

        // build the payment request
        const req = {
          email,
          firstName,
          lastName,
          message,
          formType: "events",
        };

        // send the payment request to the server
        // local endpoint
        const formApi = {
          local: "http://localhost:3001/formWaitingListAgentPurchases",
          local2:
            "https://ps5lyq6sa8.execute-api.us-east-1.amazonaws.com/default/formWaitingListAgentPurchases",
          production:
            "https://ps5lyq6sa8.execute-api.us-east-1.amazonaws.com/default/formWaitingListAgentPurchases",
        };

        fetch(formApi[env], {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // access-control-allow-origin: *

            "Access-Control-Allow-Origin": "*", // this is the important part
          },
          body: JSON.stringify(req),
        })
          .then((res) => res.json())
          .then(
            (data) => {
              console.log("data", data);
              setFormEnabled(false);
              setProgressBarValue(100);

              // if data.error is set, set the error message to formError
              if (data.error) {
                // TODO: handle error cases
                setFormError(JSON.stringify(data.error));
              } else {
                setFormSuccess(true);
              }
            } // end of .then()
          ); // end of fetch()
      })
      .catch((err) => {
        console.log("err", err);
        setFormError(err.errors[0]);
        setFormEnabled(true);
      });
  };

  const getWaitingListForm = () => {
    if (formEnabled) {
      return (
        <>
          {/* form with text box for QR code link */}
          <div className="form">
            <form>
              {/* email */}
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <br />
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Enter Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              {/* first name */}
              <div className="form-group">
                <label htmlFor="firstName">First Name</label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder="Enter First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>

              {/* last name */}
              <div className="form-group">
                <label htmlFor="lastName">Last Name</label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  placeholder="Enter Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>

              {/* message */}
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <br />
                <small id="message-help" className="form-text text-muted">
                  Tell me which event you're waiting for and/or what you're
                  interested in and how you found this site
                </small>
                <textarea
                  type="text"
                  className="form-control"
                  id="message"
                  placeholder="Enter Message"
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>

              {/* submit button */}
              <button
                type="submit"
                className="btn btn-lg btn-block btn-primary"
                style={{ fontSize: "18px" }}
                onClick={(e) => onFormSubmit(e)}
              >
                Submit
              </button>
            </form>
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  // function to convert date "May 29, 2023" to "Mon May 29"
  const convertDate = (date) => {
    const d = new Date(date);
    const day = d.toLocaleString("default", { weekday: "short" });
    const month = d.toLocaleString("default", { month: "short" });
    const dayOfMonth = d.getDate();
    return `${day} ${month} ${dayOfMonth}`;
  };

  const upcomingEvents = (isRequestForPastEvents) => (
    <>
      <div>
        {events.length > 0 ? (
          <>
            <span className="text-center" style={{ fontWeight: "bold" }}>
              {isRequestForPastEvents ? (
                <div>
                  <h2>Older Photos</h2>
                  <p>Click on an event to see the photos</p>
                </div>
              ) : (
                <></>
              )}
            </span>
          </>
        ) : (
          <>
            {loadEvents && (
              <div className="text-center">
                {/* spinner loading upcoming events */}
                <i className="fa fa-spinner fa-spin fa-3x"></i>
              </div>
            )}
          </>
        )}
        {/* display each element in events array in a compact bootstrap card */}
        {events.map((eventItem, i) => {
          // if date is before yesterday, don't show it
          const yesterday = new Date();
          yesterday.setDate(yesterday.getDate() - 1);
          const eventDate = new Date(eventItem.date);
          if (isRequestForPastEvents) {
            if (eventDate > yesterday || eventItem.showPhotos !== true) {
              return null;
            }
          } else {
            if (eventDate < yesterday) {
              return null;
            }
          }
          return (
            <button
              onClick={() => navigate(`/events/${eventItem.socialEventId}`)}
              key={i}
              className="text-decoration-none text-dark custom-button"
            >
              <div className="button-content">
                <small className="font-weight-bold">{eventItem.title}</small>
                <br />
                <small className="text-muted">
                  {convertDate(eventItem.date)} @ {eventItem.time}
                </small>
                <br />
                <small className="text-muted">@ {eventItem.location}</small>
              </div>
              <div className="button-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="purple"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={{
                    background: "#FFD166",
                    borderRadius: "50%",
                    padding: "0px",
                  }}
                >
                  <line x1="5" y1="12" x2="19" y2="12" />
                  <polyline points="12 5 19 12 12 19" />
                </svg>
              </div>
            </button>
          );
        })}
        <br />
        <br />
        {SocialLinks()}
        <button
          onClick={() => navigate("/join")}
          className="btn btn-block"
          style={{
            backgroundColor: "#71368A",
            color: "#FFFFFF",
            fontSize: "16px",
          }}
        >
          Join Group
        </button>
        <br />
        <br />

        {/* show images of upcoming events down below */}
        {events.map((eventItem, i) => {
          // if date is before yesterday, don't show it
          const yesterday = new Date();
          yesterday.setDate(yesterday.getDate() - 1);
          const eventDate = new Date(eventItem.date);
          if (isRequestForPastEvents) {
            return null;
          } else {
            if (eventDate < yesterday) {
              return null;
            }
          }
          return (
            <div key={i}>
              <button
                onClick={() => navigate(`/events/${eventItem.socialEventId}`)}
                rel="noopener noreferrer"
                style={{
                  border: "none",
                }}
              >
                <img
                  src={
                    "https://s3.amazonaws.com/social-images-public/" +
                    eventItem.imgKey
                  }
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </button>
              <br />
              <br />
            </div>
          );
        })}
      </div>
    </>
  );

  async function fetchData() {
    // create a mock function that will return a list of events after a delay
    let useMocks = false;
    if (!useMocks) {
      if (eventId) {
        // get events from this api - specific event
        await fetch(`${EVENT_API_URL}?id=${eventId}`)
          .then((res) => res.json())
          .then((data) => {
            console.log("specific event data: ", data);
            if (data && data.socialEvents && data.socialEvents.Item) {
              console.log("setting event: ", data.socialEvents.Item);
              setEvent(data.socialEvents.Item);
            } else {
            }
          })
          .catch((err) => {
            console.log("err", err);
            return [];
          });
      }
      // get events from this api - all public events
      else {
        await fetch(EVENT_API_URL)
          .then((res) => res.json())
          .then((data) => {
            console.log("public events data: ", data);
            if (data && data.socialEvents) {
              // sort events by date in ascending order
              if (getIsPastEventsQueryParams() != true) {
                console.log("sorting upcoming events");
                // toss all events that are yesterday or prior
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                data.socialEvents = data.socialEvents.filter((eventItem) => {
                  // if date is before yesterday, don't show it
                  const eventDate = new Date(eventItem.date);
                  if (eventDate < yesterday) {
                    return false;
                  }
                  return true;
                });

                data.socialEvents.sort((a, b) => {
                  return new Date(a.date) - new Date(b.date);
                });
                setEvents(data.socialEvents);
              } else {
                // sort events by date in descending order
                console.log("sorting past events");
                // toss all events that are tomorrow or prior
                const tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() + 1);
                data.socialEvents = data.socialEvents.filter((eventItem) => {
                  // if date is before tomorrow, don't show it
                  const eventDate = new Date(eventItem.date);
                  if (eventDate > tomorrow) {
                    return false;
                  }
                  return true;
                });

                data.socialEvents.sort((a, b) => {
                  return new Date(b.date) - new Date(a.date);
                });
                // setEvents(data.socialEvents); but with only previous 7 events
                setEvents(data.socialEvents);
              }

              if (eventId) {
                // return socialevent from data.socialEvents where data.socialEvents.socialEventId == eventId
                let singleEvent = data.socialEvents.filter(
                  (item) => item.socialEventId == eventId
                );
                if (singleEvent.length == 1) {
                  singleEvent = singleEvent[0];
                  console.log(`setting single event:`, singleEvent);
                  setEvent(singleEvent);
                } else {
                  console.log(
                    "ERROR: couldn't find eventId to set or filtered event array length wasn't equal to 1"
                  );
                }
              }
            } else {
              console.log(
                "called public event api, but no events were returned"
              );
            }
          })
          .catch((err) => {
            console.log("err", err);
            return [];
          });
      }
    }
    // return new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve(
    //       setEvents([
    //         {
    //           socialEventId: "1", // "top-golf-tuesday-001",
    //           title: "A Fake Event For Testing",
    //           date: "May 23, 2023",
    //           time: "7:15 PM",
    //           location: "Jax Beach Pier, Jacksonville, FL",
    //           length: "2 hours",
    //           spacesLeft: 1,
    //           cost: 10,
    //           description: "fish all you want, but eat all you fish",
    //         },
    //       ])
    //     );
    //   }, 1000);
    // });
  }

  useEffect(() => {
    if (loadEvents) {
      fetchData();
    }
  }, []);

  const breadcrumbItems = [
    { label: "Home", url: "/" },
    { label: "Events", url: "/events", active: true },
  ];

  console.log("rendering upcoming events page component");

  return (
    <>
      {/* breadcrumb links to higher pages */}
      <div style={{ alignItems: "center" }}>
        <Breadcrumb items={breadcrumbItems} />
      </div>

      <div className="outer-home-container">
        {/* button link to google drive folder */}
        <div className="text-center">
          <a
            href="https://drive.google.com/drive/folders/1A1INcKRLKdibrKNS1TUsNTKAY_FR5SzY?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button
              type="button"
              className="btn btn-lg btn-block btn-primary"
              style={{
                fontSize: "18px",
                backgroundColor: "#71368A",
                marginBottom: "20px",
              }}
            >
              Recent Photos - Google Drive
            </button>
          </a>
        </div>

        {showHavanaNightsButton && (
          <button
            onClick={() =>
              window.open(
                "https://www.dropbox.com/scl/fo/31eb3zsuvoilattognswo/AFNyl97z80-5feMEsj6ofLc?rlkey=hs7x2o6vqg117b2mv5w744c0f&e=1&st=m4ah9zqs&dl=0",
                "_blank"
              )
            }
            className="btn btn-block"
            style={{
              backgroundColor: "#333333",
              color: "#FFFFFF",
              fontSize: "24px",
              height: "48px",
              marginBottom: "20px",
            }}
          >
            Havana Nights Nov Photos
          </button>
        )}

        <hr />

        {showLinksToOldEvents == true && pastEvents == true
          ? upcomingEvents(true)
          : upcomingEvents()}

        <br />
        <br />
        <br />

        {/* show success message */}
        {successMessage && (
          <div className="alert alert-success" role="alert">
            {successMessage}
          </div>
        )}

        {/* show error message */}
        {errorMessage && (
          <div className="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        )}

        <hr />

        {/* footer */}
        <div className="footer">
          <p>&copy; {new Date().getFullYear()} David Hudman</p>
        </div>
      </div>
    </>
  );
};

export default Photos;
