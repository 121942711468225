import React, { useState } from "react"; // Fragment
import { useNavigate } from "react-router-dom";
// import the stripe buy button
import StripeBuyButton from "../../other/StripeBuyButton/StripeBuyButton";
import Breadcrumb from "../../other/Breadcrumb/Breadcrumb";

import "./host.css";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  IconButton,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faSmile,
  faUserFriends,
  faTools,
  faBook,
  faChalkboardTeacher,
  faHandHoldingHeart,
  faHandsHelping,
  faComments,
} from "@fortawesome/free-solid-svg-icons";

const hostRequirements = [
  {
    mainText: "Be on time",
    details:
      "Arrive punctually to set a good example and ensure everything starts smoothly.",
    icon: faClock,
  },
  {
    mainText: "Be friendly",
    details: "Welcome everyone with a smile and make them feel at home.",
    icon: faSmile,
  },
  {
    mainText: "Introduce newcomers",
    details: "Help new members integrate by introducing them to others.",
    icon: faUserFriends,
  },
  {
    mainText: "Be prepared",
    details: "Bring all necessary equipment like paddles, pickleballs, etc.",
    icon: faTools,
  },
  {
    mainText: "Be organized",
    details: "Know the rules and ensure everything is in order.",
    icon: faBook,
  },
  {
    mainText: "Teach beginners",
    details: "Be skilled enough to teach beginners and help them improve.",
    icon: faChalkboardTeacher,
  },
  {
    mainText: "Be patient",
    details: "Especially with beginners who may need extra guidance.",
    icon: faHandHoldingHeart,
  },
  {
    mainText: "Help those in need",
    details:
      "Spend most of your time playing with people who need the most help.",
    icon: faHandsHelping,
  },
  {
    mainText: "Communicate well",
    details: "Effective communication is key to a smooth experience.",
    icon: faComments,
  },
];

const HostRequirements = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Host Requirements
      </Typography>
      <Grid container spacing={3}>
        {hostRequirements.map((requirement, index) => (
          <Grid item xs={12} key={index}>
            <Card>
              <CardContent>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <IconButton>
                      <FontAwesomeIcon icon={requirement.icon} size="lg" />
                    </IconButton>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="h6">{requirement.mainText}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {requirement.details}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

const images = [
  "top_golf_0001.jpg",
  "sip_stroll_0001.jpg",
  "beach_volleyball_0002.jpg",
  "frisbee_0003.jpg",
  "silent_disco_ladies_0001.jpg",
];

const breadcrumbItems = [
  { label: "Home", url: "/" },
  { label: "Host", url: "/host", active: true },
];

const Host = () => {
  const navigate = useNavigate();
  const [registrationOption, setRegistrationOption] = useState(0);
  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <div className="page">
        <div
          style={{
            textAlign: "justify",
            textAlignLast: "center",
            lineHeight: "1",
          }}
        >
          {/* boostrap button to take user to https://forms.gle/gBVvuxCJmdApo7T76 to request to be an event host */}
          <button
            className="btn btn-primary"
            onClick={() =>
              window.open("https://forms.gle/gBVvuxCJmdApo7T76", "_blank")
            }
          >
            Request to be an Event Host
          </button>

          <hr />

          {HostRequirements()}

          <hr />
          {/* boostrap button to take user to https://forms.gle/gBVvuxCJmdApo7T76 to request to be an event host */}
          <button
            className="btn btn-primary"
            onClick={() =>
              window.open("https://forms.gle/gBVvuxCJmdApo7T76", "_blank")
            }
          >
            Request to be an Event Host
          </button>
        </div>

        <br />
        <div className="footer">
          <p>&copy; Active Beaches {new Date().getFullYear()}</p>
        </div>
      </div>
    </>
  );
};

export default Host;
