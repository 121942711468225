import React, { useState } from "react"; // Fragment
import { useNavigate } from "react-router-dom";
// import the stripe buy button
import StripeBuyButton from "../../other/StripeBuyButton/StripeBuyButton";
import Breadcrumb from "../../other/Breadcrumb/Breadcrumb";

import "./pickleballcoaching.css";
import "bootstrap/dist/css/bootstrap.min.css";

const images = [
  "top_golf_0001.jpg",
  "sip_stroll_0001.jpg",
  "beach_volleyball_0002.jpg",
  "frisbee_0003.jpg",
  "silent_disco_ladies_0001.jpg",
];

const breadcrumbItems = [
  { label: "Home", url: "/" },
  { label: "Pickleball Coaching", url: "/pickleball", active: true },
];

const PickleballCoaching = () => {
  const navigate = useNavigate();
  const [registrationOption, setRegistrationOption] = useState(0);
  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <div className="page">
        <div
          style={{
            textAlign: "justify",
            textAlignLast: "center",
            lineHeight: "1",
          }}
        >
          <h2
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              fontWeight: "bold",
            }}
          >
            Pickleball Coaching
          </h2>
          <h3>Upcoming Dates</h3>

          <hr />

          <h4>May 5, 1pm Jax Beach</h4>
          <hr />
          <script async src="https://js.stripe.com/v3/buy-button.js"></script>

          <stripe-buy-button
            buy-button-id="buy_btn_1PC7jyErtJD86CnFVww79wj6"
            publishable-key="pk_live_51IijezErtJD86CnFmD0ZpCGNqsPskwZBjbl34f2fW6p3vRdupBdCKIVQXsH9jXsuxtKVRnaBuSOdXbxOgD8u0K8800QcPAOKUH"
          ></stripe-buy-button>
          <hr />
          <p>$20 for 90-minute coaching lesson</p>
          <p>beginner / intermediate levels</p>
          <p>Bring your own paddle or use one of ours</p>
          <hr />
          <div style={{ marginBottom: "20px" }}>
            <img
              src={`https://s3.amazonaws.com/social-images-public/2024-04-13-pickleball-coaching/pickleball_coaching_001.png`}
              style={{
                maxWidth: "100%", // make image responsive
                display: "block", // center the image
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </div>
          <hr />
          <p>Make sure you submit your payment to secure your space</p>
          <p>Will improve your play regardless of current knowledge</p>
          <p>
            Lesson includes dinking, drops, ball serves, returns, drives, shot
            placement, strategy, and focus on specific asks by the students
          </p>
          <hr />
          <div style={{ marginBottom: "20px" }}>
            <img
              src={`https://s3.amazonaws.com/social-images-public/2024-04-13-pickleball-coaching/pickleball_coaching_005.jpg`}
              style={{
                maxWidth: "100%", // make image responsive
                display: "block", // center the image
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </div>
          <hr />
          <p>Lessons will be held in Jax Beach on Saturdays or Sundays</p>
          <p>Exact location provided at Checkout</p>
          <hr />
          <div style={{ marginBottom: "20px" }}>
            <img
              src={`https://s3.amazonaws.com/social-images-public/2024-04-13-pickleball-coaching/pickleball_coaching_006.jpg`}
              style={{
                maxWidth: "100%", // make image responsive
                display: "block", // center the image
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </div>
          {/* button with text "sign me up" */}
          {/* <button
            onClick={() => {
              setRegistrationOption(1);
            }}
            className="btn btn-primary"
          >
            Sign Me Up
          </button> */}
        </div>

        <br />
        <div className="footer">
          <p>&copy; Active Beaches {new Date().getFullYear()}</p>
        </div>
      </div>
    </>
  );
};

export default PickleballCoaching;
