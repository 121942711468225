import React, { useState } from "react"; // Fragment
import { useNavigate } from "react-router-dom";
// import the stripe buy button
import StripeBuyButton from "../../other/StripeBuyButton/StripeBuyButton";
import Breadcrumb from "../../other/Breadcrumb/Breadcrumb";

import "./salsa.css";
import "bootstrap/dist/css/bootstrap.min.css";

const images = [
  "dance-salsa-havana-nights-001.jpeg",
  "dance-salsa-havana-nights-002.jpeg",
  "dance-salsa-havana-nights-003.jpeg",
  "dance-salsa-havana-nights-004.jpeg",
  "dance-salsa-havana-nights-005.jpeg",
  "dance-salsa-havana-nights-006.jpeg",
  "dance-salsa-havana-nights-007.jpeg",
  "dance-salsa-havana-nights-008.jpeg",
  "dance-salsa-havana-nights-009.jpeg",
  "dance-salsa-havana-nights-010.jpeg",
  "dance-salsa-havana-nights-011.jpeg",
  "dance-salsa-havana-nights-012.jpeg",
];

const breadcrumbItems = [
  { label: "Home", url: "/" },
  { label: "Salsa", url: "/salsa", active: true },
];

const Salsa = () => {
  const navigate = useNavigate();
  const [registrationOption, setRegistrationOption] = useState(0);
  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <div className="page">
        <div
          style={{
            textAlign: "justify",
            textAlignLast: "center",
            lineHeight: "1",
          }}
        >
          {/* button to take user to google form */}
          <h2
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              fontWeight: "bold",
            }}
          >
            Havana Nights
          </h2>

          <p>
            Give your contact info to the organizers of Havana Nights to be
            notified of future events.
          </p>

          {/* bootstrap button to take user to https://forms.gle/2Z2bq4hUihHvmK4ZA to connect with Havana Nights */}
          <button
            className="btn btn-primary"
            onClick={() =>
              window.open("https://forms.gle/2Z2bq4hUihHvmK4ZA", "_blank")
            }
          >
            Havana Nights Connect Form
          </button>
          <br />

          <br />

          <p>Refresh the page for random photos</p>
          {/* pick three random images from images array */}
          {images
            .sort(() => 0.5 - Math.random())
            .slice(0, 3)
            .map((src, index) => (
              <div
                key={index}
                style={{ marginBottom: "20px" }} // 20px space between images
              >
                <img
                  src={`https://s3.amazonaws.com/social-images-public/main/${src}`}
                  alt={`image-${index}`}
                  style={{
                    maxWidth: "100%", // make image responsive
                    height: "auto", // auto adjust the height
                  }}
                />
              </div>
            ))}
          {/* bootstrap button to take user to https://forms.gle/2Z2bq4hUihHvmK4ZA to connect with Havana Nights */}
          <button
            className="btn btn-primary"
            onClick={() =>
              window.open("https://forms.gle/2Z2bq4hUihHvmK4ZA", "_blank")
            }
          >
            Havana Nights Connect Form
          </button>
        </div>

        <br />
        <div className="footer">
          <p>&copy; Active Beaches {new Date().getFullYear()}</p>
        </div>
      </div>
    </>
  );
};

export default Salsa;
