import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";

export default function Privacy() {
  return (
    <div className="Privacy">
      {/* Title */}
      <h1>DavidHudman.com Privacy Policy</h1>
      <br />

      {/* Privacy Policy */}
      <p>
        This Privacy Policy governs the manner in which DavidHudman.com
        collects, uses, maintains and discloses information collected from users
        (each, a "User") of the https://davidhudman.com website ("Site"). This
        privacy policy applies to the Site and all products and services offered
        by David Hudman.
      </p>

      <h2>Personal identification information</h2>
      <p>
        We may collect personal identification information from Users in a
        variety of ways, including, but not limited to, when Users visit our
        site, register on the site, and in connection with other activities,
        services, features or resources we make available on our Site. Users may
        be asked for, as appropriate, name, email address. Users may, however,
        visit our Site anonymously. We will collect personal identification
        information from Users only if they voluntarily submit such information
        to us. Users can always refuse to supply personally identification
        information, except that it may prevent them from engaging in certain
        Site related activities.
      </p>

      <h2>Non-personal identification information</h2>
      <p>
        We may collect non-personal identification information about Users
        whenever they interact with our Site. Non-personal identification
        information may include the browser name, the type of computer and
        technical information about Users means of connection to our Site, such
        as the operating system and the Internet service providers utilized and
        other similar information.
      </p>

      <h2>Web browser cookies</h2>
      <p>
        Our Site may use "cookies" to enhance User experience. User's web
        browser places cookies on their hard drive for record-keeping purposes
        and sometimes to track information about them. User may choose to set
        their web browser to refuse cookies, or to alert you when cookies are
        being sent. If they do so, note that some parts of the Site may not
        function properly.
      </p>

      <h2>How we use collected information</h2>
      <p>
        DavidHudman.com may collect and use Users personal information for the
        following purposes:
      </p>
      <ul>
        <li>
          <i>To run and operate our Site</i>
          <p>
            We may need your information display content on the Site correctly.
          </p>
        </li>
        <li>
          <i>To improve customer service</i>
          <p>
            Information you provide helps us respond to your customer service
            requests and support needs more efficiently.
          </p>
        </li>
        <li>
          <i>To personalize user experience</i>
          <p>
            We may use information in the aggregate to understand how our
            customers use the Site, determine the popularity of certain content
            and services, and more.
          </p>
        </li>
        <li>
          <i>To improve our Site</i>
          <p>
            We may use feedback you provide to improve our products and
            services.
          </p>
        </li>
        <li>
          <i>To send periodic emails</i>
          <p>
            We may use the email address to send User information and updates
            about our services.
          </p>
        </li>
      </ul>

      <h2>How we protect your information</h2>
      <p>
        We adopt appropriate data collection, storage and processing practices
        and security measures to protect against unauthorized access,
        alteration, disclosure or destruction of your personal information,
        username, password, transaction information and data stored on our Site.
      </p>

      <h2>Sharing your personal information</h2>
      <p>
        We do not sell, trade, or rent Users personal identification information
        to others. We may share generic aggregated demographic information not
        linked to any personal identification information regarding visitors and
        users with our business partners, trusted affiliates and advertisers for
        the purposes outlined above.
      </p>

      <h2>Third party websites</h2>
      <p>
        Users may find advertising or other content on our Site that link to the
        sites and services of our partners, suppliers, advertisers, sponsors,
        licensors and other third parties. We do not control the content or
        links that appear on these sites and are not responsible for the
        practices employed by websites linked to or from our Site. In addition,
        these sites or services, including their content and links, may be
        constantly changing. These sites and services may have their own privacy
        policies and customer service policies. Browsing and interaction on any
        other website, including websites which have a DavidHudman.com link at
        the top of the page, is subject to that website's own terms and
        policies.
      </p>

      <h2>Changes to this privacy policy</h2>
      <p>
        DavidHudman.com has the discretion to update this privacy policy at any
        time. When we do, we will post a notification on the main page of our
        Site, revise the updated date at the bottom of this page and send you an
        email. We encourage Users to frequently check this page for any changes
        to stay informed about how we are helping to protect the personal
        information we collect. You acknowledge and agree that it is your
        responsibility to review this privacy policy periodically and become
        aware of modifications.
      </p>

      <h2>Your acceptance of these terms</h2>
      <p>
        By using this Site, you signify your acceptance of this policy. If you
        do not agree to this policy, please do not use our Site. Your continued
        use of the Site following the posting of changes to this policy will be
        deemed your acceptance of those changes.
      </p>

      <h2>Contacting us</h2>
      <p>
        If you have any questions about this Privacy Policy, the practices of
        this site, or your dealings with this site, please contact us at:
      </p>
      <ul>
        <li>David Hudman</li>
        <li>davidhudman@hotmail.com</li>
      </ul>

      <h2>This document was last updated on May 20, 2022</h2>
    </div>
  );
}
