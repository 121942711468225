import React from "react";
// import { loadStripe } from "@stripe/stripe-js";

// const stripePromise = loadStripe(
//   "pk_live_51IijezErtJD86CnFmD0ZpCGNqsPskwZBjbl34f2fW6p3vRdupBdCKIVQXsH9jXsuxtKVRnaBuSOdXbxOgD8u0K8800QcPAOKUH"
// );

export default function StripeBuyButton() {
  //   useEffect(() => {
  //     const loadButton = async () => {
  //       const stripe = await stripePromise;
  //       const container = document.getElementById("stripe-button-container");
  //       const elements = stripe.elements();
  //       const button = elements.create("paymentRequestButton", {
  //         paymentRequest: {
  //           country: "US",
  //           currency: "usd",
  //           total: {
  //             label: "Beach Social Group Monthly Membership",
  //             amount: process.env.REACT_APP_MEMBERSHIPCOST_USD * 100, // the amount is in cents
  //           },
  //         },
  //       });
  //       button.mount(container);
  //     };

  //     loadButton();
  //   }, []);

  //   return <div id="stripe-button-container" />;
  // const BUY_BUTTON_ID = "buy_btn_1NYvFgErtJD86CnF8M8xcWUb";
  return (
    <stripe-buy-button
      buy-button-id="buy_btn_1NoGq1ErtJD86CnFF5dWKj4R"
      publishable-key="pk_live_51IijezErtJD86CnFmD0ZpCGNqsPskwZBjbl34f2fW6p3vRdupBdCKIVQXsH9jXsuxtKVRnaBuSOdXbxOgD8u0K8800QcPAOKUH"
    ></stripe-buy-button>
  );
}

//  StripeBuyButton;
