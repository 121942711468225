import React, { useState, useEffect } from "react"; // Fragment
import {
  // BrowserRouter as Router,
  // Route,
  // Routes,
  Link,
  useNavigate,
} from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import * as yup from "yup";
import Breadcrumb from "../../other/Breadcrumb/Breadcrumb";

import "./bonfire.css";
import "bootstrap/dist/css/bootstrap.min.css";

const Bonfire = () => {
  const [formEnabled, setFormEnabled] = useState(true);
  const [email, setEmail] = useState("");
  const [phone, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [sex, setSex] = useState("male");
  const [message, setMessage] = useState("");
  const [formType, setFormType] = useState("countryChristmasParty2023");
  const [formError, setFormError] = useState("");
  const [formSuccess, setFormSuccess] = useState(false);
  const [isFormHidden, setIsFormHidden] = useState(false);

  const [unlockParagraphClickCount, setUnlockParagraphClickCount] = useState(0);
  const [env, setEnv] = useState("production");
  const [progressBarValue, setProgressBarValue] = useState(0);

  // usenavigate
  const navigate = useNavigate();

  const breadcrumbItems = [
    { label: "Home", url: "/" },
    { label: "Bonfire", url: "/bonfire", active: true },
  ];

  return (
    <>
      {/* breadcrumb links to higher pages */}
      <div style={{ alignItems: "center" }}>
        <Breadcrumb items={breadcrumbItems} />
      </div>
      <div className="outer-home-container">
        <div className="home">
          <h3>Bonfire Map</h3>
          <hr />
          <h3>Pinch to Zoom</h3>
          <div
            style={{
              // text align justified and centered
              textAlign: "justify",
              textJustify: "inter-word",
            }}
          >
            <div
              style={{ marginBottom: "20px" }} // 20px space between images
            >
              <img
                src={`https://s3.amazonaws.com/social-images-public/main/bonfire_2024_02_29_directions_01.png`}
                style={{
                  maxWidth: "100%", // make image responsive
                  display: "block", // center the image
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            </div>
            <hr />
            <p>The fire is at First Street in Atlantic Beach.</p>
            <p>
              Apple / Google Maps often take you to the WRONG First Street in
              Neptune Beach or Jacksonville Beach.
            </p>
            <p>
              The correct one is in Atlantic Beach, but there's no address for
              it.
            </p>
            <p>The best place to navigate to is:</p>
            <p>
              One Ocean Resort & Spa
              <br />
              1 Ocean Blvd
              <br />
              Atlantic Beach, FL 32233
            </p>
            <p>
              <a href="https://maps.app.goo.gl/vgNLDDWb7Yv49DA66">
                Google Maps Link
              </a>
            </p>
            <hr />
          </div>
        </div>
      </div>
    </>
  );
};

export default Bonfire;
