/* eslint-disable jsx-a11y/href-no-hash */
import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";

// import Navbar from "./components/layout/Navbar";
import NavbarCustom from "./components/NavbarCustom/NavbarCustom";
// import Home from "./components/pages/Home";
import Home from "./components/pages/Home/Home";
import App1 from "./components/pages/App1/App1";
// eslint-disable-next-line import/no-named-as-default
import App2 from "./components/pages/App2/App2";
import App3 from "./components/pages/App3/App3";
import Pay from "./components/pages/Pay/Pay";
import Membership from "./components/pages/Membership/Membership";
import Subscribe from "./components/pages/Subscribe/Subscribe";
import BchMembership from "./components/pages/BchMembership/BchMembership";
import Map from "./components/pages/Map/Map";
import Terms from "./components/pages/Terms/Terms";
import Privacy from "./components/pages/Privacy/Privacy";
import Example from "./components/pages/Example/Example";
import CreateEvent from "./components/pages/CreateEvent/CreateEvent";
import Chat from "./components/pages/Chat/Chat";
import Unsubscribe from "./components/pages/Unsubscribe/Unsubscribe";
import Login from "./components/pages/Login/Login";
import Events from "./components/pages/Events/Events";
import Photos from "./components/pages/Photos/Photos";
import Bonfire from "./components/pages/Bonfire/Bonfire";
import PickleballCoaching from "./components/pages/PickleballCoaching/PickleballCoaching";
import Feedback from "./components/pages/Feedback/Feedback";
import Salsa from "./components/pages/Salsa/Salsa";
import Merch from "./components/pages/Merch/Merch";
import Host from "./components/pages/Host/Host";
import Tournament from "./components/pages/Tournament/Tournament";
import VolleyballTourneyRegistration from "./components/pages/VolleyballTourneyRegistration/VolleyballTourneyRegistration";
import Support from "./components/pages/Support/Support";

import Christmas from "./components/pages/Christmas/Christmas";
import Dance from "./components/pages/Dance/Dance";
import DataDeletion from "./components/pages/DataDeletion/DataDeletion";

const App = () => (
  <Router>
    <Fragment>
      <NavbarCustom />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/pay" element={<Pay />} />
        {/* Events path */}
        <Route path="/events" element={<Events />} />
        <Route path="/events/:eventId" element={<Events />} />
        <Route path="/create-event" element={<CreateEvent />} />
        <Route path="/create" element={<CreateEvent />} />

        {/* Host path */}
        <Route path="/host" element={<Host />} />

        {/* Bonfire path */}
        <Route path="/bonfire" element={<Bonfire />} />
        {/* Photos path */}
        <Route path="/photos" element={<Photos />} />
        <Route path="/login" element={<Login />} />
        <Route path="/example" element={<Example />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/join" element={<Chat />} />
        <Route path="/connect" element={<Chat />} />
        <Route path="/sub" element={<Subscribe />} />
        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/subscription" element={<Subscribe />} />
        <Route path="/unsubscribe" element={<Unsubscribe />} />
        <Route path="/membership" element={<Membership />} />
        <Route path="/memberships" element={<Membership />} />
        <Route path="/pickleball" element={<PickleballCoaching />} />
        <Route path="/pickle" element={<PickleballCoaching />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/salsa" element={<Salsa />} />
        <Route path="/tournament" element={<Tournament />} />
        <Route path="/support" element={<Support />} />
        <Route path="/free-volleyball-tournament" element={<Tournament />} />
        <Route
          path="/registration-vball-tourney"
          element={<VolleyballTourneyRegistration />}
        />
        <Route path="/dance" element={<Dance />} />

        <Route path="/merch" element={<Merch />} />
        <Route path="/store" element={<Merch />} />
        <Route path="/tshirt" element={<Merch />} />
        <Route path="/shirt" element={<Merch />} />
        <Route path="/shirts" element={<Merch />} />
        <Route path="/hat" element={<Merch />} />
        <Route path="/hats" element={<Merch />} />

        <Route path="/bchMembership" element={<BchMembership />} />
        <Route path="/bchmembership" element={<BchMembership />} />
        <Route path="/paybch" element={<App1 />} />
        <Route path="/map" element={<Map />} />
        {/* If you ever change /privacy or /tos - update google cloud app links for those */}
        <Route path="/tos" element={<Terms />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/data-deletion" element={<DataDeletion />} />
        <Route path="/deletion" element={<DataDeletion />} />
        <Route path="/1" element={<App1 />} />
        <Route path="/2" element={<App2 />} />
        <Route path="/3" element={<App3 />} />

        <Route path="/country" element={<Dance />} />
        <Route path="/xmas" element={<Christmas />} />
        <Route path="/usa" element={<Christmas />} />
      </Routes>
    </Fragment>
  </Router>
);

export default App;
