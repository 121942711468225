import React, { useState, useEffect } from "react"; // Fragment
import {
  // BrowserRouter as Router,
  // Route,
  // Routes,
  Link,
  useNavigate,
} from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import * as yup from "yup";
import Breadcrumb from "../../other/Breadcrumb/Breadcrumb";

import "./christmas.css";
import "bootstrap/dist/css/bootstrap.min.css";

const Christmas = () => {
  const [formEnabled, setFormEnabled] = useState(true);
  const [email, setEmail] = useState("");
  const [phone, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [sex, setSex] = useState("male");
  const [message, setMessage] = useState("");
  const [formType, setFormType] = useState("countryChristmasParty2023");
  const [formError, setFormError] = useState("");
  const [formSuccess, setFormSuccess] = useState(false);
  const [isFormHidden, setIsFormHidden] = useState(false);

  const [unlockParagraphClickCount, setUnlockParagraphClickCount] = useState(0);
  const [env, setEnv] = useState("production");
  const [progressBarValue, setProgressBarValue] = useState(0);

  // usenavigate
  const navigate = useNavigate();

  const breadcrumbItems = [
    { label: "Home", url: "/" },
    { label: "Country Party", url: "/country", active: true },
  ];

  // setTimeout to update the progressBarValue by 1 every 100ms
  useEffect(() => {
    const interval = setInterval(() => {
      if (progressBarValue < 100) {
        setProgressBarValue(progressBarValue + 1);
      }
    }, 50);
    return () => clearInterval(interval);
  }, [progressBarValue]);

  const onFormSubmit = (e) => {
    e.preventDefault();
    setFormError("");

    // validate form against yup schema
    const schema = yup.object().shape({
      email: yup.string().max(100).email().required(),
      firstName: yup.string().max(100).required(),
      lastName: yup.string().max(100).required(),
      sex: yup.string().max(6).required(),
      message: yup.string().max(1000).optional(),
      phone: yup.string().max(1000).required(),
    });

    schema
      .validate({ email, firstName, lastName, sex, message, phone })
      .then(() => {
        setFormEnabled(false);
        setProgressBarValue(0);

        // build the payment request
        const req = {
          email: email ? email : "blank",
          firstName: firstName ? firstName : "blank",
          lastName: lastName ? lastName : "blank",
          phone: phone ? phone : "blank",
          sex,
          message: "July4 party RSVP; message: " + message,
          formType,
        };

        // send the payment request to the server
        // local endpoint
        const formApi = {
          local: "http://localhost:3001/formWaitingListAgentPurchases",
          local2:
            "https://ps5lyq6sa8.execute-api.us-east-1.amazonaws.com/default/formWaitingListAgentPurchases",
          production:
            "https://ps5lyq6sa8.execute-api.us-east-1.amazonaws.com/default/formWaitingListAgentPurchases",
        };

        fetch(formApi[env], {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // access-control-allow-origin: *

            "Access-Control-Allow-Origin": "*", // this is the important part
          },
          body: JSON.stringify(req),
        })
          .then((res) => res.json())
          .then(
            (data) => {
              console.log("data", data);
              setFormEnabled(false);
              setProgressBarValue(100);

              // if data.error is set, set the error message to formError
              if (data.error) {
                // TODO: handle error cases
                setFormError(JSON.stringify(data.error));
              } else {
                setFormSuccess(true);
              }
            } // end of .then()
          ); // end of fetch()
      })
      .catch((err) => {
        console.log("err", err);
        setFormError(err.errors[0]);
        setFormEnabled(true);
      });
  };

  const getWaitingListForm = () => {
    if (formEnabled) {
      return (
        <>
          {/* form with text box for QR code link */}
          <div className="form">
            <form>
              {/* first name */}
              <div className="form-group">
                <label htmlFor="firstName">First Name</label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder="Enter First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>

              {/* last name */}
              <div className="form-group">
                <label htmlFor="lastName">Last Name</label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  placeholder="Enter Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>

              <div className="form-group" style={{ textAlign: "center" }}>
                <label>Sex</label>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ marginRight: "20px" }}>
                    <input
                      type="radio"
                      id="male"
                      name="sex"
                      value="male"
                      onChange={() => setSex("male")}
                      checked={sex === "male"}
                    />
                    <label htmlFor="male">Male</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="female"
                      name="sex"
                      value="female"
                      onChange={() => setSex("female")}
                      checked={sex === "female"}
                    />
                    <label htmlFor="female">Female</label>
                  </div>
                </div>
                <br />
              </div>

              {/* email */}
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <br />
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Enter Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              {/* Phone Number */}
              <div className="form-group">
                <label htmlFor="phoneNumber">Phone Number</label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  id="phoneNumber"
                  placeholder="Enter Phone Number"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>

              {/* message */}
              <div className="form-group">
                <label htmlFor="message">
                  Questions / Comments / Suggestions
                </label>
                <small id="message-help" className="form-text text-muted">
                  Leave any questions, comments, or suggestions here
                </small>
                <textarea
                  type="text"
                  className="form-control"
                  id="message"
                  placeholder="Enter Questions"
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <br />

              {/* Error Message Display Div */}
              {formError ? (
                <div
                  id="error-message"
                  className="alert alert-danger"
                  role="alert"
                >
                  <strong>Error!</strong> {formError}
                </div>
              ) : null}

              {/* submit button */}
              <button
                type="submit"
                className="btn btn-lg btn-block btn-primary"
                style={{
                  fontSize: "20px",
                  backgroundColor: "black",
                  color: "orange",
                  fontWeight: "bold",
                }}
                onClick={(e) => onFormSubmit(e)}
              >
                Submit
              </button>
            </form>
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {/* breadcrumb links to higher pages */}
      <div style={{ alignItems: "center" }}>
        <Breadcrumb items={breadcrumbItems} />
      </div>
      <div className="outer-home-container">
        <div className="home">
          <h3>Independence Party Signup</h3>
          <h5>Spring Park, Green Cove Springs, FL</h5>
          <h5>5pm-11pm June 28, 2024</h5>
          <p>Check back here for ongoing updates.</p>
          <h2>Signup at the bottom of the page!</h2>
          <div
            style={{
              // text align justified and centered
              textAlign: "justify",
              textJustify: "inter-word",
            }}
          >
            <div
              style={{ marginBottom: "20px" }} // 20px space between images
            >
              <img
                src={`https://s3.amazonaws.com/social-images-public/country_dance_0002.jpg`}
                style={{
                  maxWidth: "100%", // make image responsive
                  display: "block", // center the image
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            </div>
            <hr />
            <h4 style={{ textAlign: "center" }}>Updates</h4>
            <h4 style={{ textAlign: "center" }}>Who?</h4>
            <p>
              Pretty much everyone who comes out to Honkytonkhammerheads events.
            </p>
            {/* <h4 style={{ textAlign: "center" }}>Current RSVPs</h4>
          <p>
            <ul
              style={{
                // no bullets
                listStyleType: "none",
              }}
            >
              <li>People here</li>
            </ul>
          </p> */}
            <h4 style={{ textAlign: "center" }}>When/Where?</h4>
            <p>
              We will be at the pavillion at Spring Park in Green Cove Springs.
            </p>
            <h4 style={{ textAlign: "center" }}>What?</h4>
            <p>
              Organized by René Mette and Jessica Mette. They are in the
              @honkytonkhammerheads group so please give them a follow on
              Instagram as well!
            </p>
            <p>
              The venue is reserved for our group, and there will be dinner
              catered. Come dressed in Red White and Blue style!
            </p>
            <p>
              tickets:
              <br />
              $20 before Jun 28th
              <br />
              $25 day of event
            </p>
            <p>
              please pay Jessica on Venmo or Cash App (but cash is preferred if
              you see her in person)
              <br />
              Venmo: @Jessica-Mette-2
              <br />
              Cash App: $jmette00
            </p>
            <p>
              Please pay as soon as possible so we can get a head count and
              before the prices increase.
            </p>
            <p>
              My name is David. I'm 6'2" and built like a giraffe so please find
              me and say hello. I'll introduce you to the group also.
            </p>
            <hr />
          </div>
          {/* see more button that toggles setIsFormHidden */}
          <div className="text-center" hidden={true}>
            <button
              type="button"
              className="btn btn-lg btn-block btn-secondary"
              style={{
                fontSize: "18px",
                marginTop: "15px",
                marginBottom: "15px",
              }}
              onClick={() => setIsFormHidden(!isFormHidden)}
            >
              {isFormHidden ? "Signup" : "See Less"}
            </button>
          </div>
          <div hidden={isFormHidden}>
            <h3>Sign Up</h3>
            {/* muted text */}
            <p className="text-muted">
              Sign up here first then proceed to pay Jessica on Venmo or CashApp
            </p>

            {/* Error Message Display Div */}
            {formError ? (
              <div
                id="error-message"
                className="alert alert-danger"
                role="alert"
              >
                <strong>Error!</strong> {formError}
              </div>
            ) : null}

            {/* Success Message Display Div */}
            {formSuccess ? (
              <div
                id="success-message"
                className="alert alert-success"
                role="alert"
              >
                <strong>Success!</strong> We received your entry. Please proceed
                to pay Jessica on Venmo or CashApp or cash if you see her ahead
                of time. We will be in touch soon. Thank you!
              </div>
            ) : null}

            {/* Loading Message Display Div */}
            {!formEnabled && !formError && progressBarValue != 100 ? (
              <>
                <div
                  id="loading-message"
                  className="alert alert-info"
                  role="alert"
                >
                  <strong>
                    If you are not shown a success message within 10 seconds,
                    please refresh the page and try again.
                  </strong>
                </div>
                {/* create a loading spinner with inline css */}
                <ProgressBar now={progressBarValue} animated />
              </>
            ) : null}

            {getWaitingListForm()}

            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default Christmas;
