import React from "react"; // Fragment
import {
  // BrowserRouter as Router,
  // Route,
  // Routes,
  Link,
} from "react-router-dom";

import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";

export default function Example() {
  return (
    <div className="outer-page-container">
      <div className="page">
        <h1>Title</h1>
        <h4>Subtitle</h4>

        <br />

        {/* page content */}
        <div className="page-content">
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
        </div>

        <div className="home-links">
          <a
            href="#"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-lg btn-block btn-primary"
          >
            Page Link
          </a>
          <br />
        </div>

        {/* footer */}
        <div className="footer">
          <br />
          <br />
          <p>&copy; {new Date().getFullYear()} Beach Social Group</p>
          <p>
            <a
              href="http://davidhudman.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Designed by David Hudman
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
