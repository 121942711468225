import React, { useState } from "react"; // Fragment
import { useNavigate } from "react-router-dom";
// import the stripe buy button
import StripeBuyButton from "../../other/StripeBuyButton/StripeBuyButton";
import Breadcrumb from "../../other/Breadcrumb/Breadcrumb";

import "./feedback.css";
import "bootstrap/dist/css/bootstrap.min.css";

const images = [
  "top_golf_0001.jpg",
  "sip_stroll_0001.jpg",
  "beach_volleyball_0002.jpg",
  "frisbee_0003.jpg",
  "silent_disco_ladies_0001.jpg",
];

const breadcrumbItems = [
  { label: "Home", url: "/" },
  { label: "Feedback", url: "/feedback", active: true },
];

const Feedback = () => {
  const navigate = useNavigate();
  const [registrationOption, setRegistrationOption] = useState(0);
  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <div className="page">
        <div
          style={{
            textAlign: "justify",
            textAlignLast: "center",
            lineHeight: "1",
          }}
        >
          {/* button to take user to google form "concerns" */}
          <h2
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              fontWeight: "bold",
            }}
          >
            Feedback
          </h2>

          <p>
            If you have any concerns about the group, please click the button
            below to report them.
          </p>

          {/* bootstrap button to take user to https://forms.gle/59dMaz86BPQqmx3N6 to report concerns */}
          <button
            className="btn btn-primary"
            onClick={() =>
              window.open("https://forms.gle/59dMaz86BPQqmx3N6", "_blank")
            }
          >
            Report Concerns
          </button>

          <hr />

          <p>
            If you have any ideas for the group to make it better, please click
            the button below to provide your feedback.
          </p>
          {/* boostrap button to take user to https://forms.gle/swpfQEx5FJa7HgpC9 to provide ideas for the group */}
          <button
            className="btn btn-primary"
            onClick={() =>
              window.open("https://forms.gle/swpfQEx5FJa7HgpC9", "_blank")
            }
          >
            Suggest Ideas
          </button>
        </div>

        <br />
        <div className="footer">
          <p>&copy; Active Beaches {new Date().getFullYear()}</p>
        </div>
      </div>
    </>
  );
};

export default Feedback;
