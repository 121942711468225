import React, { useState } from "react"; // Fragment
import { useNavigate } from "react-router-dom";
// import the stripe buy button
import StripeBuyButton from "../../other/StripeBuyButton/StripeBuyButton";
import Breadcrumb from "../../other/Breadcrumb/Breadcrumb";

import "./membership.css";
import "bootstrap/dist/css/bootstrap.min.css";

const images = [
  "top_golf_0001.jpg",
  "sip_stroll_0001.jpg",
  "beach_volleyball_0002.jpg",
  "frisbee_0003.jpg",
  "silent_disco_ladies_0001.jpg",
];

const breadcrumbItems = [
  { label: "Home", url: "/" },
  { label: "Membership", url: "/membership", active: true },
];

const Membership = () => {
  const navigate = useNavigate();
  const [registrationOption, setRegistrationOption] = useState(0);
  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <div className="page">
        <div style={{ textAlign: "justify", textAlignLast: "center" }}>
          <h4
            style={{
              marginTop: "-10px",
              marginBottom: "-10px",
              fontWeight: "bold",
            }}
          >
            Membership
          </h4>

          <hr />

          {/* women join here */}
          <div style={{ textAlign: "center" }}>
            <button
              onClick={() => setRegistrationOption("women")}
              className="btn btn-lg btn-block btn-default"
              style={{
                backgroundColor: "#FFA4A4",
                color: "white",
                width: "100%",
              }}
            >
              Women Join Here
            </button>
          </div>

          {/* men join here */}
          <div style={{ textAlign: "center" }}>
            <button
              onClick={() => setRegistrationOption("men")}
              className="btn btn-lg btn-block btn-default"
              style={{
                backgroundColor: "#3E92CC",
                color: "white",
                width: "100%",
                marginTop: "10px",
              }}
            >
              Men Join Here
            </button>
          </div>

          <hr />

          {registrationOption == 0 ? (
            <div>
              <p style={{ lineHeight: 1.1 }}>
                Event locations are shown to members only. <br />{" "}
                <span
                  style={{
                    fontStyle: "italic",
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  Women join free.
                </span>{" "}
                Men pay ${process.env.REACT_APP_MEMBERSHIPCOST_USD} / mo.
              </p>
              <p style={{ lineHeight: 1.1 }}>
                We see this fee as a challenge - an opportunity to step up,
                support our community, and show respect for our female members.
                Are you ready to join our ranks? See you at the next event 🥳
              </p>
            </div>
          ) : registrationOption == "men" ? (
            <div>
              <div style={{ textAlign: "justify", textAlignLast: "center" }}>
                <h4
                  style={{
                    marginTop: "-10px",
                    marginBottom: "-10px",
                    fontWeight: "bold",
                  }}
                >
                  Mens Membership
                </h4>
                <hr />
                <p style={{ lineHeight: 1.1 }}>
                  To view event locations in the general group annoucments-only
                  chat, you must be a member. Men must pay a monthly $
                  {process.env.REACT_APP_MEMBERSHIPCOST_USD} subscription fee{" "}
                  <a
                    href="https://buy.stripe.com/8wMdUS74j9nA5sA289"
                    target="_blank"
                    style={{
                      fontStyle: "italic",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    HERE
                  </a>
                  . Everyone will be able to see titles of upcoming events, but
                  only members will be able to see the locations.
                </p>
                <p style={{ lineHeight: 1.1, display: "none" }}>
                  If men pay in Bitcoin Cash, they only pay $
                  {process.env.REACT_APP_MEMBERSHIPCOST_BCH} per month, but they
                  must pay in 6 month increments - $
                  {process.env.REACT_APP_MEMBERSHIPCOST_BCH * 6} total. You can
                  pay with Bitcoin Cash{" "}
                  <a
                    href="/paybch"
                    style={{
                      fontStyle: "italic",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    HERE
                  </a>
                  . You may have to contact me after you've paid so I can verify
                  the payment status and add you as a member to our group, so
                  please contact the group account on Instagram by{" "}
                  <a
                    href={
                      "https://instagram.com/" +
                      process.env.REACT_APP_INSTAGRAM_HANDLE
                    }
                    target="_blank"
                    style={{
                      fontStyle: "italic",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    following us
                  </a>{" "}
                  and sending a direct message.
                </p>
                <p style={{ lineHeight: 1.1 }}>
                  We see this fee as a challenge - an opportunity to step up,
                  support our community, and show respect for our female
                  members. Are you ready to join our ranks? See you at the next
                  event 🥳
                </p>
              </div>

              {/* stripe buy button */}
              <StripeBuyButton />
            </div>
          ) : registrationOption == "women" ? (
            <div>
              {/* womens verification process */}
              <div style={{ textAlign: "justify", textAlignLast: "center" }}>
                <h4
                  style={{
                    marginTop: "-10px",
                    marginBottom: "-10px",
                    fontWeight: "bold",
                  }}
                >
                  Women's Verification Options
                </h4>
                <hr />
                <p style={{ lineHeight: 1.1 }}>
                  Option 1: Get recommendation from any woman or admin in the
                  group
                </p>
                <p style={{ lineHeight: 1.1 }}>
                  Option 2:{" "}
                  <a
                    href={
                      "https://instagram.com/" +
                      process.env.REACT_APP_INSTAGRAM_HANDLE
                    }
                    target="_blank"
                    style={{
                      fontStyle: "italic",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    Follow us
                  </a>{" "}
                  on Instagram, and approve us to follow you so we can see that
                  there are photos of you from a while back and it seems legit
                  to us.
                </p>
                <p style={{ lineHeight: 1.1 }}>
                  Option 3: Meet us at a public event so we can see that you are
                  who you say you are.
                </p>
                <p style={{ lineHeight: 1.1 }}>
                  Option 4: Follow the{" "}
                  <a
                    href="/membership-men"
                    style={{
                      fontStyle: "italic",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    mens registration
                  </a>{" "}
                  process, and pay the monthly fee. Once we meet you at a public
                  or private event, you will no longer be required to pay the
                  monthly fee.
                </p>
              </div>
            </div>
          ) : null}
        </div>
        {/* <a
        href="#"
        className="btn btn-lg btn-block btn-default"
        style={{ backgroundColor: "green", color: "white" }}
      >
        <span style={{ fontSize: ".7em" }}>Stripe Checkout - Coming soon!</span>
        <br />
        <span style={{ fontSize: ".7em" }}>
          ${process.env.REACT_APP_MEMBERSHIPCOST_USD} / month
        </span>
      </a> */}
        {/* <script async src="https://js.stripe.com/v3/buy-button.js"></script>
      <stripe-buy-button
        buy-button-id="buy_btn_1NYvFgErtJD86CnF8M8xcWUb"
        publishable-key="pk_live_51IijezErtJD86CnFmD0ZpCGNqsPskwZBjbl34f2fW6p3vRdupBdCKIVQXsH9jXsuxtKVRnaBuSOdXbxOgD8u0K8800QcPAOKUH"
      ></stripe-buy-button> */}

        {/* stripe buy button */}
        {/* <StripeBuyButton /> */}

        <div style={{ padding: "10px" }}>
          {images.map((src, index) => (
            <div
              key={index}
              style={{ marginBottom: "20px" }} // 20px space between images
            >
              <img
                src={`https://s3.amazonaws.com/social-images-public/${src}`}
                alt={`image-${index}`}
                style={{
                  maxWidth: "100%", // make image responsive
                  height: "auto", // auto adjust the height
                }}
              />
            </div>
          ))}
        </div>

        <hr />

        {/* stripe buy button */}
        {/* <StripeBuyButton /> */}

        <br />
      </div>
    </>
  );
};

export default Membership;
