import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  username: Yup.string().required("Required"),
});

export default function BchMembership() {
  const [invoiceAmount, setInvoiceAmount] = useState(
    process.env.REACT_APP_MEMBERSHIPCOST_BCH
  );
  const [tipAmount, setTipAmount] = useState(0);
  const [showPayButton, setShowPayButton] = useState(false);
  const [forWhat, setForWhat] = useState("");
  const [email, setEmail] = useState("");
  const [showIframe, setShowIframe] = useState(true);
  const [step, setStep] = useState(1);

  const formik = useFormik({
    initialValues: {
      email: "",
      username: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setEmail(values.email);
      setForWhat(values.username);
      setStep(2);
    },
  });

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <form onSubmit={formik.handleSubmit}>
            <div className="form-group input-group-lg">
              <label
                className="lb-lg"
                htmlFor="emailInput"
                style={{ fontSize: "1.3em" }}
              >
                Email:
              </label>
              <br />
              <input
                id="emailInput"
                name="email"
                type="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                className="form-control text-center"
                style={{ fontSize: "1.3em" }}
              />
              {formik.errors.email ? (
                <div style={{ color: "red", fontWeight: "bold" }}>
                  {formik.errors.email}
                </div>
              ) : null}

              <br />

              <label
                className="lb-lg"
                htmlFor="usernameInput"
                style={{ fontSize: "1.3em" }}
              >
                Discord Username:
              </label>
              <br />
              <input
                id="usernameInput"
                name="username"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.username}
                className="form-control text-center"
                style={{ fontSize: "1.3em" }}
              />
              {formik.errors.username ? (
                <div style={{ color: "red", fontWeight: "bold" }}>
                  {formik.errors.username}
                </div>
              ) : null}
            </div>
            <br />
            <button type="submit" className="btn btn-lg btn-block btn-primary">
              Next
            </button>
          </form>
        );
      case 2:
        return (
          <div>
            <div className="alert alert-info" role="alert">
              <h3>Step 1</h3>
              {isiOS() ? (
                <p>
                  Tap the copy icon (do not press and hold) below the QR code to
                  copy the address and paste it into your wallet or exchange to
                  send funds.
                </p>
              ) : (
                <p>
                  Click the "Pay in Wallet" button to open your wallet or tap
                  the copy icon (do not press and hold) to copy the address
                  below the QR code and paste it into your wallet or exchange to
                  send funds.
                </p>
              )}
              <h3>Step 2</h3>
              <p>Complete Payment in your wallet or exchange to send funds</p>
              <h3>Step 3</h3>
              <p>View this page again to see that the payment was completed</p>
              <h3>Step 4</h3>
              <p>We will update your Discord access within 24 hours</p>
              <br />
              <h4>
                Any Issues?&nbsp;
                {/* when user clicks on a tag, it should refresh the iframe after 500 ms */}
                <a
                  href="#"
                  onClick={() => {
                    setShowIframe(false);
                    setTimeout(() => {
                      setShowIframe(true);
                    }, 500);
                  }}
                >
                  Refresh Checkout.
                </a>
              </h4>
            </div>

            <iframe
              id="prompt-frame"
              scrolling="no"
              style={{ overflow: "hidden" }}
              width="360"
              height="800"
              src={
                `https://prompt.cash/pay-frame?token=${process.env.REACT_APP_PROMPT_CASH_TOKEN}&currency=USD&tx_id=` +
                Date.now() +
                "&amount=" +
                process.env.REACT_APP_MEMBERSHIPCOST_BCH +
                "&desc=" +
                forWhat.replace(/-/g, "")
                // +
                // "&callback=" +
                // `${process.env.REACT_APP_STAGE_CALLBACK_API}`
              }
            ></iframe>
          </div>
        );
      default:
        return <></>;
    }
  };

  const changeInvoiceAmount = (e) => {
    if (isNaN(e.target.value) || e.target.value <= 0) {
      // show error message
      setShowPayButton(false);
    } else {
      setInvoiceAmount(e.target.value);
      setShowPayButton(true);
    }
  };

  const getTotalAmount = () => {
    if (isNaN(tipAmount) || tipAmount < 0) {
      return parseFloat(invoiceAmount).toFixed(2);
    } else {
      return (parseFloat(invoiceAmount) + parseFloat(tipAmount)).toFixed(2);
    }
  };

  const isiOS = () => {
    const userAgent = navigator.userAgent;
    return (
      userAgent.includes("iPhone") ||
      userAgent.includes("iPad") ||
      userAgent.includes("iPod") ||
      (userAgent.includes("Safari") && !userAgent.includes("Chrome"))
    );
  };

  const milestones = () => {
    return (
      <nav aria-label="breadcrumb" className="mt-3">
        <ol className="breadcrumb bg-light rounded">
          <li className="breadcrumb-item">
            <span
              className={`${
                step === 1
                  ? "display-4 text-primary bg-info p-2 rounded"
                  : "large text-success"
              }`}
            >
              <i
                className={`fas fa-arrow-right ${step === 1 ? "" : "d-none"}`}
              ></i>{" "}
              Enter Email & Username
            </span>
          </li>
          <li className="breadcrumb-item">
            <span
              className={`${
                step === 2
                  ? "display-4 text-primary bg-info p-2 rounded"
                  : "large text-success"
              }`}
            >
              <i
                className={`fas fa-arrow-right ${step === 2 ? "" : "d-none"}`}
              ></i>{" "}
              Complete Payment
            </span>
          </li>
        </ol>
      </nav>
    );
  };

  return (
    <div className="page">
      {/* Title */}
      <h3>Bitcion Cash (BCH) Checkout</h3>
      {milestones()}

      {renderStep()}
    </div>
  );
}
