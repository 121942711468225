import React from "react"; // Fragment
import { Link, useNavigate } from "react-router-dom";
import Breadcrumb from "../../other/Breadcrumb/Breadcrumb";

import "./pay.css";
import "bootstrap/dist/css/bootstrap.min.css";

const breadcrumbItems = [
  { label: "Home", url: "/" },
  { label: "Events", url: "/events" },
  { label: "Donate or Pay", url: "/pay", active: true },
];

const Pay = () => {
  const navigate = useNavigate();

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <div className="pay">
        <div>
          <h3>Pay</h3>
          {/* <h5>Pay in Bitcoin Cash to get a 40% discount</h5> */}
          <hr />
          <p style={{ textAlign: "center" }}>
            Pay with any of these methods
            {/* <span>
              If you need to buy Bitcoin Cash (BCH), the easiest way is to
              purchase it through{" "}
              <a href="https://www.coinbase.com/join/hudman_d">Coinbase</a>.
              Once you have a Coinbase account, you will need to verify your
              identity with them in order to send Bitcoin Cash (BCH) to us.
            </span> */}
          </p>
        </div>
        {/* <hr />
        <h2>Checkout</h2> */}
        <hr />
        <button
          onClick={() => navigate(`/paybch`)}
          className="btn btn-lg btn-block btn-success"
        >
          <span style={{ fontSize: "1em" }}>Bitcoin Cash (BCH)</span>
          {/* <br />
        <span style={{ fontSize: ".8em" }}>$12 / month</span>
        <br />
        <span style={{ fontSize: ".8em", fontWeight: "bold", color: "green" }}>
          40% discount
        </span> */}
        </button>
        <br />
        <br />
        <button
          onClick={() =>
            window.open("https://www.venmo.com/davidhudman/", "_blank")
          }
          className="btn btn-lg btn-block btn-primary"
        >
          <span style={{ fontSize: "1em" }}>Venmo - @davidhudman</span>
          {/* <br />
        <span style={{ fontSize: ".7em" }}>$20 / month</span> */}
        </button>
        <br />
        <br />
        {/* cash app */}
        <button
          onClick={() => window.open("https://cash.app/$payhudman", "_blank")}
          className="btn btn-lg btn-block btn-success"
        >
          <span style={{ fontSize: "1em" }}>Cash App - $payhudman</span>
          {/* <br />
        <span style={{ fontSize: ".7em" }}>$20 / month</span> */}
        </button>
        <br />
      </div>
    </>
  );
};

export default Pay;
