import React, { useState } from "react"; // Fragment
import { useNavigate } from "react-router-dom";
// import the stripe buy button
import StripeBuyButton from "../../other/StripeBuyButton/StripeBuyButton";
import Breadcrumb from "../../other/Breadcrumb/Breadcrumb";

import "./dance.css";
import "bootstrap/dist/css/bootstrap.min.css";

const images = [
  "top_golf_0001.jpg",
  "sip_stroll_0001.jpg",
  "beach_volleyball_0002.jpg",
  "frisbee_0003.jpg",
  "silent_disco_ladies_0001.jpg",
];

const breadcrumbItems = [
  { label: "Home", url: "/" },
  { label: "Dance", url: "/dance", active: true },
];

const Dance = () => {
  const navigate = useNavigate();
  const [registrationOption, setRegistrationOption] = useState(0);
  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <div className="page">
        <div
          style={{
            textAlign: "justify",
            textAlignLast: "center",
            lineHeight: "1",
          }}
        >
          <h1>Dance 🤠</h1>
          {/* youtube video with image preview but in mobile view proportions - https://youtube.com/shorts/6EjED9tM-Wk?si=mj72mehWE9BHteuK  - do it*/}
          <p>
            Country Swing Lesson - Lead Hair Flip, Ride, Sweetheart, Arm Throw
            Spin Sequence - Nov 4 2024
          </p>
          <div className="youtube-container">
            <iframe
              width="250px"
              height="400px"
              src="https://www.youtube.com/embed/6EjED9tM-Wk?si=mj72mehWE9BHteuK"
              title="YouTube video player"
              frameBorder="0"
            ></iframe>
          </div>
        </div>

        <br />
        <div className="footer">
          <p>&copy; Active Beaches {new Date().getFullYear()}</p>
        </div>
      </div>
    </>
  );
};

export default Dance;
